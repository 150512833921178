<template>
  <b-row>
    <b-col cols="12">
      <div
        class="d-flex align-items-center justify-content-between my-2 pagination-ctn"
      >
        <div class="count">
          {{
            totalRows > 0
              ? fromToOver.from + " à " + fromToOver.to + " sur "
              : ""
          }}
          {{ totalRows }} {{ $t("pagination.results") }}
        </div>
        <div class="pagination justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="per_page"
          />
        </div>
        <div class="per-page d-flex align-items-center justify-content-between">
          <div class="mr-1">
            <label for="per-page" class="font-bold ml-1">
              {{ $t("pagination.show_by") }} :
            </label>
          </div>
          <div class="">
            <b-form-select v-model="per_pageLocal" :options="per_pageValues" />
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BPagination, VBTooltip, BFormSelect } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BPagination,
    VBTooltip,
    BFormSelect,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    per_page: {
      type: Number,
      default: 10,
    },
    totalRows: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPage: 1,
      per_pageLocal: this.$props.per_page,
      per_pageValues: [2, 10, 20, 100, 200],
    };
  },
  computed: {
    tableDataLength() {
      return (this.tableData && this.tableData.length) || 0;
    },
    fromToOver() {
      let from = this.currentPage * this.per_pageLocal - this.per_pageLocal;
      if (from < 0) from = 0;

      let to = from + this.per_pageLocal;
      if (to > this.totalRows) to = this.totalRows;

      if (from === 0) from = 1;
      if (to === 0) from = 0;
      return { from, to };
    },
  },
  watch: {
    currentPage: {
      handler(val, old) {
        this.emitPaginationOutput();
      },
    },
    per_pageLocal: {
      handler(val, old) {
        this.emitPaginationOutput();
      },
    },
  },
  methods: {
    emitPaginationOutput() {
      this.$emit("change", {
        per_page: this.per_pageLocal,
        currentPage: this.currentPage,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@core/scss/base/bootstrap-extended/include";

ul.pagination.b-pagination {
  margin-bottom: 0;
}
.pagination-ctn {
  @media (max-width: 768px) {
    justify-content: center !important;
    gap: 10px;
    flex-wrap: wrap;
  }
}

.pagination {
  @media (max-width: 768px) {
    justify-content: center !important;
    width: 100%;
  }
}
</style>
